"use client";
import { supabase } from 'src/configs/supabaseClient';
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useAuth } from '../useAuth';

// Define the structure for a single employee
interface ReminderSettingsData {
  reminder_value: string;
  reminder_unit: string;
  reminder_id: string;
  user_id: any;
}

export const useGetReminderSettingsByUserId = (user_id: string): UseQueryResult<ReminderSettingsData, Error> => {
  const auth = useAuth();

  return useQuery({
    queryKey: ['reminder_settings', user_id],
    queryFn: async () => {
      if (!user_id) {
        return [];
      }

      const { data, error } = await supabase
        .from('reminder_settings')
        .select('*')
        .limit(1);

      if (error) {
        throw new Error(error.message);
      }

      // Return the first employee that matches the email
      return data ? data[0] : null;
    },
    enabled: !!auth?.user, // Only run the query if the user is logged in
  });
};

