"use client";
import { supabase } from 'src/configs/supabaseClient';
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useAuth } from '../useAuth';

// Define the structure for a list of roles
interface CompanyData {
  company_name: string;
  ein: string;
  company_phone_number: string;
  company_address: string;
  status: string;
}

export const useGetNumberOfVehicles = (): UseQueryResult<CompanyData[], Error> => {
  const auth = useAuth();

  return useQuery({
    queryKey: ['vehicles'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('vehicles')
        .select('*');

      if (error) {
        throw new Error(error.message);
      }

      return data
    },

    enabled: !!auth?.user,
  });
};

