"use client";
import { supabase } from 'src/configs/supabaseClient';
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useAuth } from 'src/hooks/useAuth';
import { useGetReminderSettingsByUserId } from 'src/hooks/reminder-settings/useGetReminderSettingsByUserId';

interface ReminderExpirationsData {
  company_id: string;
  vehicle_id: string;
  driver_id: string;
  file_type: string;
  expiration_date: any;
  isEmailSent: boolean;
  file_name: string;
  company_name: string;
  sent_to_email_address: string;
  vehicle_document_expirations_id: string;
  driver_document_expirations_id: string;
}

export const useGetAllReminders = (): UseQueryResult<ReminderExpirationsData[], Error> => {
  const auth = useAuth();

  const {
    data: reminderSettingsData,
    isLoading: isReminderSettingsLoading,
    isError: isReminderSettingsError
  } = useGetReminderSettingsByUserId(auth?.user?.id || '');

  const calculateExpiryThreshold = (reminderValue: any, reminderUnit: any) => {
    const currentDate = new Date();
    if (reminderUnit === 'months') {
      currentDate.setMonth(currentDate.getMonth() + parseInt(reminderValue));
    } else if (reminderUnit === 'weeks') {
      currentDate.setDate(currentDate.getDate() + 7 * parseInt(reminderValue));
    }

    return currentDate;
  };

  return useQuery({
    queryKey: ['reminders'],
    queryFn: async () => {
      const expiryThreshold = calculateExpiryThreshold(reminderSettingsData?.reminder_value, reminderSettingsData?.reminder_unit);
      const formattedExpiryThreshold = expiryThreshold.toISOString().split('T')[0];

      const { data: vehicleData, error: vehicleError } = await supabase
        .from('vehicle_document_expirations')
        .select('*')
        .lte('expiration_date', formattedExpiryThreshold)



      const { data: driverData, error: driverError } = await supabase
        .from('driver_document_expirations')
        .select('*')
        .lte('expiration_date', formattedExpiryThreshold)
       


      if (vehicleError || driverError) {
        throw new Error(vehicleError?.message || driverError?.message);
      }

      return [...vehicleData, ...driverData];
    },
    enabled: !!auth?.user && !isReminderSettingsLoading
  });
};


