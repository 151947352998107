import * as React from 'react';
import { ReactElement } from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Icon from 'src/@core/components/icon'
import Popover from '@mui/material/Popover';

// ** Types
import { ThemeColor } from 'src/@core/layouts/types'

// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar'
import CardSalesInCountries from 'src/@core/components/dashboard/UpcomingReminders'
import AllUsersOverview from 'src/@core/components/dashboard/AllUsersOverview';
import UpcomingReminders from 'src/@core/components/dashboard/UpcomingReminders';
import { CircularProgress, Tooltip } from '@mui/material';
import ProtectedRoute from 'src/context/ProtectedRoute';
import { useAuth } from 'src/hooks/useAuth';
import { useGetNumberOfCompanies } from 'src/hooks/dashboard/useGetNumberOfCompanies';
import { useGetNumberOfDrivers } from 'src/hooks/dashboard/useGetNumberOfDrivers';
import { useGetNumberOfVehicles } from 'src/hooks/dashboard/useGetNumberOfVehicles';
import { useGetNumberOfEmployees } from 'src/hooks/dashboard/useGetNumberOfEmployees';

interface SaleDataType {
  stats: string
  title: string
  color: ThemeColor
  icon: ReactElement
}

const salesData: SaleDataType[] = [
  {
    stats: '390',
    color: 'primary',
    title: 'Vehicle',
    icon: <Icon icon='mdi:truck' />
  },
  {
    stats: '212',
    color: 'primary',
    title: 'drivers',
    icon: <Icon icon='mdi:steering' />
  },
  {
    color: 'info',
    stats: '420',
    title: 'Files',
    icon: <Icon icon='mdi:file-document-multiple-outline' />
  }
]

const Home = () => {
  const calculateDaysUntilDue = () => {
    const now = new Date();
    const taxDay = new Date(now.getFullYear(), 3, 15); // April is month 3 in JavaScript (0-indexed)
    if (now > taxDay) {
      taxDay.setFullYear(taxDay.getFullYear() + 1); // If today's date is past April 15, calculate days until next year's tax day
    }
    const diffTime = Math.abs(taxDay.getTime() - now.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  const auth = useAuth();


  function ProfitCard() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const infoIconRef = React.useRef(null);
    const [numberOfCompanies, setNumberOfCompanies] = React.useState<number>(0);
    const auth = useAuth();
    const { data: numberOfCompanieshook, isLoading, isError, refetch } = useGetNumberOfCompanies();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <Grid item sx={{ paddingBottom: '10px' }}>
        <Card style={{ height: '100%' }} aria-describedby={id}>
          <CardHeader
            title='Total companies'
            subheader='List of all companies csd'
            action={
              <>
                <IconButton aria-label="settings" onClick={handleClick} ref={infoIconRef}>
                  <Icon icon='mdi:info-outline' fontSize={18} />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={infoIconRef.current}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiPopover-paper': {
                      width: {
                        xs: '50%',
                        sm: '20%'
                      },
                      maxWidth: 'none',
                      padding: '0px',
                      borderRadius: '10px',
                    }
                  }}
                >
                  <Typography sx={{
                    p: 2,
                    fontSize: 12,
                    backgroundColor: 'black',
                    color: 'white',
                  }}>
                    All companies in the system. Click on a company to view more details.
                  </Typography>
                </Popover>
              </>
            }
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <Grid
              sx={{
                alignItems: 'center',
                display: 'flex',

              }}
            >
              <CustomAvatar skin='light' variant='rounded' color='primary' sx={{ mr: 4 }}>
                <Icon icon='mdi:building' width={30} />
              </CustomAvatar>
              <Typography variant="h4" component="div" sx={{ mb: '2px' }} fontWeight={800}>
                {numberOfCompanieshook ? numberOfCompanieshook.length : 0}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  const EcommerceSalesOverview = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const infoIconRef = React.useRef(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const { data: numberOfDrivershook, isLoading: isLoadingDrivers, refetch: refetchDrivers } = useGetNumberOfDrivers();
    const { data: numberOfVehicles, isLoading: isLoadingVehicles, refetch: refetchVehicles } = useGetNumberOfVehicles();
    const { data: numberOfEmployees, isLoading, isError, refetch } = useGetNumberOfEmployees();

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
        <Card style={{ flex: 1 }}>
          <CardHeader
            sx={{ pb: 3.25 }}
            title='Overview'
            subheader='Quick overview of your business'
            titleTypographyProps={{ variant: 'h6' }}
            action={
              <>
                <IconButton aria-label="settings" onClick={handleClick} ref={infoIconRef}>
                  <Icon icon='mdi:info-outline' fontSize={18} />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={infoIconRef.current}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiPopover-paper': {
                      width: {
                        xs: '50%',
                        sm: '20%'
                      },
                      maxWidth: 'none',
                      padding: '0px',
                      borderRadius: '10px',
                    }
                  }}
                >
                  <Typography sx={{
                    p: 2,
                    fontSize: 12,
                    backgroundColor: 'black',
                    color: 'white',
                  }}>
                    This is quick overview of your business and how it's doing.
                  </Typography>
                </Popover>
              </>
            }
          />
          <CardContent>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4} >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CustomAvatar skin='light' variant='rounded' color='primary' sx={{ mr: 4 }}>
                    <Icon icon='mdi:truck' />
                  </CustomAvatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='h6' sx={{ fontWeight: 600 }}>
                      {numberOfVehicles ? numberOfVehicles.length : 0}
                    </Typography>
                    <Typography variant='caption'>All Vehicles</Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CustomAvatar skin='light' variant='rounded' color='primary' sx={{ mr: 4 }}>
                    <Icon icon='mdi:steering' />
                  </CustomAvatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='h6' sx={{ fontWeight: 600 }}>
                      {numberOfDrivershook ? numberOfDrivershook.length : 0}
                    </Typography>
                    <Typography variant='caption'>All Drivers</Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CustomAvatar skin='light' variant='rounded' color='primary' sx={{ mr: 4 }}>
                    <Icon icon='mdi:work' />
                  </CustomAvatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='h6' sx={{ fontWeight: 600 }}>
                      {numberOfEmployees ? numberOfEmployees.length : 0}
                    </Typography>
                    <Typography variant='caption'>All Employees</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  return (
    <ProtectedRoute>
      {auth?.userInfo?.can_manage === "NEW_USER_BLANK" || auth?.userInfo?.can_manage === "authenticated" || auth?.userInfo?.can_manage !== "admin_manage" ? (
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography variant='h5'>You are not authorized to view this page.</Typography>
          <Typography variant='body1'>Please contact your administrator.</Typography>
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2}>
            {/* Updated Grid container for left side cards to align heights */}
            <Grid
              item xs={12} sm={8}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'space-between',
              }}>
              <ProfitCard />
              <EcommerceSalesOverview />
            </Grid>

            {/* Right side card */}
            <Grid item xs={12} sm={4} style={{ paddingLeft: '10px' }}>
              <UpcomingReminders />
            </Grid>

            {/* <Grid item xs={12} sx={{ marginTop: 2, marginBottom: { xs: 2, sm: 0 } }}>
              <AllUsersOverview />
            </Grid> */}

          </Grid>
        </Box>
      )}

    </ProtectedRoute>
  )
}

export default Home
