// ** React Imports
import { ReactNode } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import * as React from 'react';
import Button from '@mui/material/Button';
import Icon from 'src/@core/components/icon'
import { Grid } from '@mui/material'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { useRouter } from 'next/navigation'
import { useGetAllReminders } from 'src/hooks/reminders/useGetAllReminders'

interface DataType {
  title: string
  sales: string
  trend: ReactNode
  trendNumber: string
}

const UpcomingReminders = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const infoIconRef = React.useRef(null);
  const router = useRouter()
  const [filteredReminders, setFilteredReminders] = React.useState<any>([])

  const {
    data: allReminders,
    isLoading,
    isError,
    refetch
  } = useGetAllReminders()

  const applyFilters = React.useCallback(() => {
    const filtered = allReminders?.filter((reminder: any) => {
      return reminder.isExpirationDateUpdated === false
    })


    setFilteredReminders(filtered);
  }, [allReminders]);

  React.useEffect(() => {
    refetch();
    applyFilters();
  }, [allReminders, applyFilters, refetch]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    router.push('/reminders')
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Card sx={{ height: 'fit-content' }}>
      <Grid item xs={12}>
        <CardHeader
         color='white'
          title='Upcoming reminders'
          subheader='All expiring documents and licenses'
          subheaderTypographyProps={{ sx: { lineHeight: 1.429 } }}
          titleTypographyProps={{ sx: { letterSpacing: '0.15px' } }}
        />
        {filteredReminders && filteredReminders?.length === 0 && (
          <Box sx={{  display: 'flex', alignItems: 'center', ml: 5 }}>
            <Typography variant='body2' color='text.secondary'>
              You have no upcoming reminders. sdfs
            </Typography>
          </Box>
        )}
        <CardContent>
          {filteredReminders && filteredReminders?.slice(0, 3).map((reminder: any) => (
            <Box sx={{ mb: 6, display: 'flex', alignItems: 'center' }} key={reminder.id}>
              <CustomAvatar color='primary' skin='light' variant='rounded' sx={{ mr: 3, width: 45, height: 45 }}>
              <Icon icon='mdi:bell-ring' color='primary' width={18} height={18} />
              </CustomAvatar>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='body2' sx={{ mb: 0.5, fontWeight: 600, color: 'text.primary' }}>
                  {reminder?.file_type}
                </Typography>
                <Typography variant='caption' >Send reminder now</Typography>
              </Box>
            </Box>
          ))}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button variant='outlined' color='error' onClick={handleClick}>
                View All Reminders
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Grid>
    </Card>
  )
}

export default UpcomingReminders
